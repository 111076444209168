<template>
  <vx-card class="ticket-detail main-box top-zero-radius md:w-3/4 mx-auto">
    <div class="general-info" :class="{'is-mobile': isMobile}">
      <div class="general-info-items">
        <h1 class="subject">
          {{ ticket.subject }}
        </h1>
        <div class="general-info-item">
          {{ $t('tickets.organization.detail.labels.ticketId') }}
          {{ ticket.id }}
        </div>
        <div class="general-info-item">
          {{ ticket.department }}
        </div>
      </div>
      <div class="general-info-items left">
        <div class="general-info-item">
          <span class="label">
          {{ $t('tickets.organization.detail.labels.status') }}
          </span>
          <span class="content">
            {{ statusTypes[ticket.status] }}
          </span>
        </div>
        <div class="general-info-item">
          <span class="label">
          {{ $t('tickets.organization.detail.labels.createdAt') }}
          </span>
          <span class="content date">
            {{ ticket.date }}
          </span>
        </div>
        <div class="general-info-item">
          <span class="label">
          {{ $t('tickets.organization.detail.labels.lastUpdate') }}
          </span>
          <span class="content">
            {{ ticket.lastUpdate }}
          </span>
        </div>
      </div>
    </div>
    <div class="message-section">
      <template v-for="(message, key) in ticket.messages">
        <div :key="key" class="message" :class="{'client': message.client}">
          <div class="user-avatar">
            <img :src="message.userAvatar" :alt="message.creator" class="user-image">
          </div>

          <div class="message-body">
            <h2 class="message-header">
              <span class="user-name">
                {{ message.creator }}
              </span>
              |
              <span class="user-company">
              {{ message.company }}
            </span>
            </h2>
            <p class="message-text">
              <vue-markdown>
                {{ message.body }}
              </vue-markdown>
            </p>

            <div v-if="message.attaches.length > 0" class="attach-list">
              <template v-for="(attach, attach_key) in message.attaches">
                <a :key="`${key}_${attach_key}`" :href="attach.link" target="_blank" class="attach-link">
                  <custom-icon icon="EXPORT" :color-code="message.client ? '#000' : '#fff'" height="20px" width="20px" />
                  <span class="attach-name">
                    {{ attach.name }}
                  </span>
                </a>
              </template>
            </div>
          </div>
          <div class="message-date">
            <span class="time">{{ message.time }}</span>
            <span class="date">{{ message.date }}</span>
          </div>
        </div>
      </template>
    </div>


    <!--  answer ticket prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="answerTicketPromptStatus"
      @close="answerTicketPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('answerTicketBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('tickets.organization.answer.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="answerTicketPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <answer-organization-ticket @insert="handleReloadTicket" />
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <vs-button id="sendMessageTicketBTN"
               v-show="false"
               v-if="checkUserPermissions('organizational_ticket.create')"
               @click="$refs.sendConfirmation.showDialog()">
    </vs-button>

    <custom-dialog ref="sendConfirmation"
                   :title="$t('tickets.organization.confirmations.send.title')"
                   :body="$t('tickets.organization.confirmations.send.body')"
                   @accept="sendMessageTicket"/>


    <button v-show="false"
            id="answerTicket"
            @click="answerTicketPromptStatus = true"/>
  </vx-card>
</template>

<script>
import moment from 'moment-jalaali'
import VueMarkdown from 'vue-markdown'
import CustomIcon from "../../../../../components/customIcon/customIcon";
import {getOrganizationTicket, sendOrganizationTicketToService} from "../../../../../http/requests/tickets";
import {checkUserPermissions, getAttachUrl, getAvatarUrl, getStaticFileUrl} from "../../../../../assets/js/functions";
import AnswerOrganizationTicket from "./answer/answerOrganizationTicket";
import CustomDialog from "../../../../../components/customDialog/customDialog";
import axios from "axios";

export default {
  name: 'ticketOrganizationDetail',
  metaInfo () {
    return {
      title: this.$t('tickets.organization.detail.title')
    }
  },
  components: {CustomDialog, AnswerOrganizationTicket, CustomIcon, VueMarkdown},
  data () {
    return {
      answerTicketPromptStatus: false,
      statusTypes: {
        0: this.$t('tickets.organization.statusTypes.created'),
        1: this.$t('tickets.organization.statusTypes.creatorAnswer'),
        2: this.$t('tickets.organization.statusTypes.receiverAnswer'),
        3: this.$t('tickets.organization.statusTypes.pending'),
        4: this.$t('tickets.organization.statusTypes.completed')
      },
      operatorInfo: {
        id: 0,
        name: ''
      },
      ticket: {
        id: null,
        subject: '',
        department: '',
        status: 0,
        date: '',
        lastUpdate: '',
        messages: []
      },
      actions: [
        {
          toolbar: [
            {
              id: 'answerTicket',
              color: 'success',
              icon: 'PLUS',
              iconPack: 'useral',
              permission: 'organizational_ticket.create'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'organizationTickets'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.windowWidth < this.$store.state.mobileScreen
    },
  },
  created () {
    // this.$vs.loading()
    this.$nextTick(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', ' md:w-3/4 mx-auto')
    })

    this.getTicket()
  },
  methods: {
    getTicket () {
      getOrganizationTicket(this.$route.params.id).then((response) => {
        const ticket = response.data.data
        this.ticket.id = ticket.id
        this.ticket.date = ticket.started_at
        this.ticket.subject = ticket.subject
        this.ticket.status = ticket.status
        this.ticket.lastUpdate = moment(ticket.answered_at, this.$localeValidator('moment.dateTime')).locale(this.$i18n.locale).fromNow()

        // let actions = JSON.parse(JSON.stringify(this.actions))
        //
        // if (!ticket.sent) {
        //   actions[0].toolbar.push({
        //     id: 'sendMessageTicketBTN',
        //     color: 'warning',
        //     icon: 'SEND',
        //     iconPack: 'useral',
        //     permission: 'organizational_ticket.create'
        //   })
        // }
        // this.$store.dispatch('updateNavbarActions', actions[0])

        ticket.messages.forEach((message) => {
          let attaches = []
          let attachCount = 1
          message.attachments.forEach((attach) => {
            attaches.push({
              name: this.$t('tickets.organization.labels.dynamicAttach', {number: attachCount}),
              link: getAttachUrl(attach)
            })
            attachCount++
          })

          let messageItem = {
            userAvatar: message.user.avatar ? getAvatarUrl(message.user.avatar) : message.user.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
            creator: `${message.user.name || ''} ${message.user.family || ''}`,
            company: message.user.role,
            body: message.message || '',
            client: message.by_me,
            date: message.sent_at.split(' ')[0],
            time: message.sent_at.split(' ')[1],
            attaches: attaches
          }

          this.ticket.messages.push(messageItem)
        })
      })
    },
    handleReloadTicket () {
      this.ticket = {
        id: null,
        subject: '',
        department: '',
        status: 0,
        date: '',
        lastUpdate: '',
        messages: []
      }
      this.answerTicketPromptStatus = false
      this.getTicket()
    },

    sendMessageTicket () {
      sendOrganizationTicketToService(this.$route.params.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('tickets.organization.notifications.sendMessage.success'),
          icon: 'icon-check',
          iconPack: 'feather',
          time: 2400,
          color: 'success'
        })

        this.handleReloadTicket()
      })
        .catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('tickets.organization.notifications.sendMessage.error'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        })
    },

    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },

    handleClick(id) {
      document.getElementById(id).click()
    },
    consoleLog (log) {
      console.log(log)
    }
  }
}
</script>

<style lang="scss" scoped>
  .ticket-detail {
    .general-info {
      display: flex;
      border-bottom: 1px solid #cdcdcd;

      .general-info-items {
        width: 50%;

        &.left {
          .general-info-item {
            display: flex;
            justify-content: flex-end;
          }
        }

        .general-info-item {
          display: flex;
          line-height: 40px;

          .label {
            margin: 0 5px;
          }

          .content {
            &.date {
              direction: rtl;
            }
          }
        }

        .subject {
          font-size: 17px;
          line-height: 40px;
        }
      }

      &.is-mobile {
        flex-direction: column;

        .general-info-items {
          width: 100%;

          &.left {
            .general-info-item {
              justify-content: flex-start;
            }
          }
        }
      }
    }

    .message-section {
      padding: 20px 0;

      .message {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 10px;

        .user-avatar {
          height: 40px;
          width: 40px;

          .user-image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .message-body {
          width: calc(100% - 110px);
          max-width: 500px;
          margin: 0 10px;
          padding: 15px;
          background-color: #4e7fe0;
          color: #ffffff;
          border-radius: 0.5rem 0 0.5rem 0.5rem;

          .message-header {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            font-size: 15px;
            color: #ffffff;
            margin-bottom: 20px;

            .user-name {
              margin: 0 5px;
            }

            .user-company {
              margin: 0 5px;
            }
          }

          .attach-list {
            margin-top: 20px;

            .attach-link {
              display: flex;
              align-items: center;
              color: inherit;

              .attach-name {
                margin: 0 10px;
              }
            }
          }
        }

        .message-date {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          width: 70px;
        }

        &.client {
          flex-direction: row;

          .message-body {
            border-radius: 0 0.5rem 0.5rem 0.5rem;
            background-color: #eff0f7;
            color: #000000;

            .message-header {
              color: #000000;
              text-align: left;
              flex-direction: row;
            }
          }

          .message-date {
            align-items: flex-start;
          }
        }
      }
    }
  }
</style>
